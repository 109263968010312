import React from "react"
import { graphql } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"

import Layout from "../assets/styles/layout"

import CaseStudyHeader from "../components/case-study/CaseStudyHeader"
import {
  FooterContainer,
  GrayContainer,
} from "../components/global/common/containers"

import CallToAction from "../components/global/CallToAction"
import Footer from "../components/global/Footer"
import Effects from "../components/case-study/Effects"
import Testimonial from "../components/case-study/Testimonial"
import AboutTheProject from "../components/case-study/AboutTheProject.js"
import Gallery from "../components/case-study/Gallery"
import ImportantPoints from "../components/case-study/ImportantPoints"
import Mockups from "../components/case-study/Mockups"
import Movie from "../components/case-study/Movie"
import Screenshots from "../components/case-study/Screenshots"
import BeforeAndAfter from "../components/case-study/BeforeAndAfter"
import AboutTheWork from "../components/case-study/AboutTheWork"
import Scope from "../components/case-study/Scope"
import Executors from "../components/case-study/Executors"
import EndingNav from "../components/case-study/EndingNav"

export const query = graphql`
  query ($id: String!) {
    wpCaseStudy(id: { eq: $id }) {
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      featuredImage {
        node {
          altText
          atttachement_pl {
            altTextPl
          }
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
      acfCallToAction {
        ctaPersonPosition
        ctaPersonName
        ctaForm
        ctaImage {
          altText
          atttachement_pl {
            altTextPl
          }
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        ctaContactOptions {
          eMail
          phone
        }
        ctaButtons {
          ctaButton1 {
            url
            title
            target
          }
          ctaButton2 {
            target
            url
            title
          }
        }
        ctaIntro
        ctaTitle
      }
      acfCaseStudy {
        testimonialSectionExists
        isBigTestimonial
        longContent
        personImage {
          altText
          atttachement_pl {
            altTextPl
          }
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        personName
        personPosition
        testimonialHeader
        intro
        csYtMovie
        csScreenshotsTitle
        csScreenshotsExists
        csScreenshotsDarkerBackground
        csScreenshots {
          altText
          atttachement_pl {
            altTextPl
          }
          id
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        csScopeTitle
        csScopeExists
        csScope {
          csScopeDescription
        }
        csMovieTitle
        csMovieExists
        csMovieDescription
        csMockupsExists
        csMockups {
          altText
          atttachement_pl {
            altTextPl
          }
          id
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        csLiveLink {
          url
          title
          target
        }
        csImportantPointsTitle
        csImportantPointsMockup {
          altText
          atttachement_pl {
            altTextPl
          }
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        csImportantPointsExists
        csImportantPoints {
          csImportantPointsSubTitle
          csImportantPointsDescription
          csImportantPointsImage {
            altText
            atttachement_pl {
              altTextPl
            }
            localFile {
              childImageSharp {
                gatsbyImageData(width: 500, quality: 92, layout: CONSTRAINED)
              }
              extension
              publicURL
            }
          }
        }
        csGrayLogo {
          altText
          atttachement_pl {
            altTextPl
          }
          localFile {
            publicURL
          }
        }
        whiteLogo {
          altText
          atttachement_pl {
            altTextPl
          }
          localFile {
            publicURL
          }
        }
        csGalleryTitle
        csGalleryExists
        csGallery {
          id
          altText
          atttachement_pl {
            altTextPl
          }
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
            publicURL
          }
        }
        csFirstDescription
        csExecutorsTitle
        csExecutorsExists
        csExecutors {
          csExecutorRole
          csExecutorPerson {
            ... on WpTeamMember {
              id
              slug
              acfTeamMember {
                singleUserName
                singleUserPosition
                singleUserImage {
                  altText
                  atttachement_pl {
                    altTextPl
                  }
                  localFile {
                    childImageSharp {
                      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
          }
        }
        csEffectsTitle
        csEffectsMockup {
          altText
          atttachement_pl {
            altTextPl
          }
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        csEffectsList
        csEffectsExists
        csEffects {
          description
          csEffectsLogo
          csEffectsNumber
        }
        csBeforeAfterTitle
        csBeforeAfterExists
        csBefore {
          altText
          atttachement_pl {
            altTextPl
          }
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        csAfter {
          altText
          atttachement_pl {
            altTextPl
          }
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        csAboutTitle
        indentedSectionTitle
        indentedSectionSubTitle
        indentedSectionImage {
          altText
          atttachement_pl {
            altTextPl
          }
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        indentedSectionExists
        indentedSectionDescription
        csAboutImage {
          altText
          atttachement_pl {
            altTextPl
          }
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        csAboutExists
        csAboutDescription
      }
    }
  }
`

const CaseStudy = ({ pageContext, data: { wpCaseStudy } }) => {
  const { seo } = wpCaseStudy
  const caseStudyData = wpCaseStudy.acfCaseStudy

  const is = {
    effects: caseStudyData.csEffectsExists,
    testimonial: caseStudyData.testimonialSectionExists,
    aboutTheProject: caseStudyData.csAboutExists,
    gallery: caseStudyData.csGalleryExists,
    importantPoints: caseStudyData.csImportantPointsExists,
    mockups: caseStudyData.csMockupsExists,
    movie: caseStudyData.csMovieExists,
    screenshots: caseStudyData.csScreenshotsExists,
    beforeAndAfter: caseStudyData.csBeforeAfterExists,
    aboutTheWork: caseStudyData.indentedSectionExists,
    scope: caseStudyData.csScopeExists,
    team: caseStudyData.csExecutorsExists,
  }

  return (
    <Layout>
      {seo && process.env.GATSBY_SITE_ENV === "production" && (
        <Seo post={wpCaseStudy} />
      )}
      <CaseStudyHeader data={wpCaseStudy} pageContext={pageContext} />
      {is.effects && <Effects data={caseStudyData} />}
      {is.testimonial && <Testimonial data={caseStudyData} />}
      {is.aboutTheProject && <AboutTheProject data={caseStudyData} />}
      {is.gallery && <Gallery data={caseStudyData} />}
      {is.importantPoints && <ImportantPoints data={caseStudyData} />}
      {is.mockups && <Mockups mockups={caseStudyData.csMockups} />}
      {is.movie && <Movie data={caseStudyData} />}
      <GrayContainer darker={caseStudyData.csScreenshotsDarkerBackground}>
        {is.screenshots && <Screenshots data={caseStudyData} />}
        {is.beforeAndAfter && <BeforeAndAfter data={caseStudyData} />}
      </GrayContainer>
      {is.aboutTheWork && <AboutTheWork data={caseStudyData} />}
      {is.scope && <Scope data={caseStudyData} />}
      {is.team && <Executors data={caseStudyData} />}
      <EndingNav />
      <FooterContainer>
        <CallToAction data={wpCaseStudy.acfCallToAction} />
        <Footer />
      </FooterContainer>
    </Layout>
  )
}

export default CaseStudy
